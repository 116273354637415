<template>
    <ion-page>
        <ion-header :translucent="true">
            <ion-toolbar>
                <ion-title color="white">Carica foto</ion-title>
            </ion-toolbar>
        </ion-header>

        <ion-content :fullscreen="true">
            <div class="page_content">
                <div v-if="processingPhotos" class="loading_spinner">
                    <ion-text color="dark">
                        <h5 class="ion-text-center">Salvataggio in corso, il processo potrebbe richiedere del tempo in base alla connessione</h5>
                        <h5 class="ion-text-center">{{ submitted }} di {{ allPhotosCounter }} processate correttamente</h5>
                    </ion-text>
                    <ion-spinner name="crescent" color="dark"></ion-spinner>
                </div>

                <div v-else>
                    <div class="container_actions">
                        <div @click="closeModal()" class="btn_actions_images">Chiudi</div>
                        <div @click="savePhoto()" class="btn_actions_images">Salva</div>
                    </div>

                    <div class="category_container">
                        <ion-select
                            name="tipologia"
                            interface="action-sheet"
                            cancel-text="Annulla"
                            ok-text="Conferma"
                            v-model="categoriaSelezionata"
                            value="1"
                            placeholder="Seleziona la categoria"
                            @ionChange="setCategoria($event.target.value)"
                        >
                            <ion-select-option
                                v-for="categoria in categorieFoto"
                                :key="categoria.rapportini_immagini_categoria_id"
                                :value="categoria.rapportini_immagini_categoria_id"
                            >
                                {{ categoria.rapportini_immagini_categoria_value }}
                            </ion-select-option>
                        </ion-select>

                        <span @click="removeSelectedCategory()">
                            <ion-icon :icon="close" class="remove_category"></ion-icon>
                        </span>
                    </div>

                    <div class="category_container">
                        <ion-select
                            name="operatori"
                            interface="action-sheet"
                            cancel-text="Annulla"
                            ok-text="Conferma"
                            v-model="dipendenteSelezionato"
                            placeholder="Seleziona operatore"
                            @ionChange="setOperatore($event.target.value)"
                        >
                            <ion-select-option v-for="operatore in dipendenti" :key="operatore.dipendenti_id" :value="operatore.dipendenti_id">
                                {{ `${operatore.dipendenti_cognome} ${operatore.dipendenti_nome}` }}
                            </ion-select-option>
                        </ion-select>

                        <span @click="removeSelectedDipendente()">
                            <ion-icon :icon="close" class="remove_category"></ion-icon>
                        </span>
                    </div>

                    <!-- <div class="foto_button_container">
                        <button class="foto_button" @click="showActionSheetPhotos()">Seleziona foto</button>
                    </div> -->

                    <div style="margin: 24px 0;">
                        <div class="foto_button" ref="fotoButton" @click="triggerCameraButton()">Scatta foto</div>
                        <input
                            type="file"
                            id="cameraInput"
                            accept="image/*"
                            capture="environment"
                            style="display: none;"
                            ref="inputButton"
                            @change="getPhoto($event)"
                        />
                    </div>

                    <div style="margin-bottom: 24px;">
                        <div class="foto_button" ref="multipleFotoButton" @click="triggerMultipleCameraButton()">Carica dalla galleria</div>
                        <input
                            type="file"
                            id="cameraInput"
                            accept="image/*"
                            multiple
                            style="display: none;"
                            ref="multipleInputButton"
                            @change="getPhotosFromGallery($event)"
                        />
                    </div>

                    <div v-if="loadingMultiplePhotos" class="loading_photos">
                        <h5 class="ion-text-center">Elaborazione foto in corso...</h5>
                        <ion-spinner name="crescent" color="dark"></ion-spinner>
                    </div>

                    <div v-if="!loadingMultiplePhotos || (photos && photos.length != 0)" class="step_img_container">
                        <ion-grid>
                            <ion-row>
                                <ion-col size="6" v-for="(foto, index) in photos" :key="index">
                                    <div class="singola_foto">
                                        <ion-thumbnail @click="removePhoto(foto)">
                                            <img :src="foto.tmp_src" alt="" srcset="" class="img_photo" />
                                        </ion-thumbnail>
                                    </div>
                                </ion-col>
                            </ion-row>
                        </ion-grid>
                    </div>

                    <!-- <div class="photos_container">
                        <ion-grid>
                            <ion-row>
                                <ion-col size="6" :key="photo" v-for="photo in userPhoto">
                                    <ion-img v-if="photo.photoFromGallery" :src="photo.webviewPath" class="img_photo"></ion-img>
                                    <ion-img v-else :src="`data:image/png;base64,${photo.webviewPath}`" class="img_photo"></ion-img>
                                    <div @click="showActionSheet(photo)" class="img_action">
                                        <ion-icon :icon="trash"></ion-icon>
                                    </div>
                                </ion-col>
                            </ion-row>
                        </ion-grid>
                    </div> -->
                </div>
            </div>
        </ion-content>
    </ion-page>
</template>

<script>
import {
    IonPage,
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    modalController,
    IonGrid,
    IonRow,
    IonCol,
    //IonImg,
    IonIcon,
    actionSheetController,
    IonText,
    IonSelect,
    IonSelectOption,
    IonSpinner,
    IonThumbnail,
} from "@ionic/vue";
import { camera, trash, images, close } from "ionicons/icons";
import { defineComponent, ref, reactive, onMounted } from "vue";

import { openToast } from "@/services/toast";
import exifr from "exifr";
import Compressor from "compressorjs";

import apiRapportini from "@/services/rapportini";
import apiDipendenti from "@/services/dipendenti";
import apiPlan from "@/services/impostazioni_plan";

//import { usePhotoGallery, UserPhoto } from "@/services/usePhotoGalleryRapportino";
import { usePhotoGallery, UserPhoto } from "@/services/immagini_rapportino";
import imageToBase64 from "image-to-base64/browser";

export default defineComponent({
    name: "FotoRapportino",
    props: {
        images: {
            type: Array,
            required: false,
        },
        rapportino_id: {
            type: String,
            required: true,
        },
    },
    components: {
        IonPage,
        IonContent,
        IonHeader,
        IonTitle,
        IonToolbar,
        IonGrid,
        IonRow,
        IonCol,
        //IonImg,
        IonIcon,
        IonText,
        IonSelect,
        IonSelectOption,
        IonSpinner,
        IonThumbnail,
    },
    setup(props, context) {
        //console.log(props);

        //Camera functionality
        const { userPhoto, takePhoto, deletePhoto, pickPhotos, deleteAllPhotos } = usePhotoGallery();
        const processingPhotos = ref(false);
        const dipendenteID = JSON.parse(localStorage.getItem("userPlanInfo")).dipendenti_id;

        /********************************************
         *
         * ! IMPOSTAZIONI MODULO PLAN
         *
         ********************************************/
        const richiediPosizione = ref(false);
        async function loadImpostazioni() {
            const res = await apiPlan.getImpostazioni();
            if (res.status === 200 && res.data.status === 0) {
                //settings.value = res.data.data;
                if (res.data.data.length != 0) {
                    richiediPosizione.value =
                        res.data.data[0].impostazioni_plan_geolocalizzazione_real_time && res.data.data[0].impostazioni_plan_geolocalizzazione_real_time == 1
                            ? true
                            : false;
                }
            }
        }
        loadImpostazioni();

        /**
         * ! Handle close modal by clicking on Chiudi button or after upload foto
         * ! Send back data to visualize photos in Modal Pulizia form
         */
        const closeModal = () => {
            modalController.dismiss();
        };

        async function closeModalOnSubmit(res) {
            if (res) {
                await modalController.dismiss(res);
            } else {
                await modalController.dismiss();
            }
        }

        /*****************************************************************
         *
         * ! Selezione e rimozione categoria selezionata per le foto
         *
         ******************************************************************/
        const categorieFoto = ref([]);
        let selectedPhotoCategory = null;
        const categoriaSelezionata = ref(null);

        async function loadCategorie() {
            try {
                const res = await apiRapportini.getCategorieFoto();
                if (res.status === 200 && res.data.status === 0) {
                    categorieFoto.value = res.data.data;
                } else {
                    openToast("Errore durante la richiesta delle categorie", "toast_danger");
                }
            } catch (error) {
                console.error(error);
                openToast("Errore durante la richiesta delle categorie", "toast_danger");
            }
        }

        async function setCategoria(categoria_id) {
            if (categoria_id && categoria_id != "") {
                categoriaSelezionata.value = categoria_id;
                //await showActionSheetPhotos(categoria_id);
            } else {
                categoriaSelezionata.value = null;
            }
        }

        function removeSelectedCategory() {
            categoriaSelezionata.value = null;
        }

        /**
         * ! Set and remove selected dipendente
         */
        const dipendenti = ref([]);
        const dipendenteSelezionato = ref(null);

        async function loadDipendenti() {
            try {
                const res = await apiDipendenti.getDipendenti();
                if (res.status === 200 && res.data.status === 0) {
                    dipendenti.value = res.data.data;
                } else {
                    openToast("Errore durante la richiesta degli operatori", "toast_danger");
                }
            } catch (error) {
                console.error(error);
                openToast("Errore durante la richiesta degli operatori", "toast_danger");
            }
        }
        async function setOperatore(dipendente_id) {
            if (dipendente_id && dipendente_id != "") {
                dipendenteSelezionato.value = dipendente_id;
                //await showActionSheetPhotos(dipendente_id);
            } else {
                dipendenteSelezionato.value = null;
            }
        }

        setOperatore(dipendenteID);

        function removeSelectedDipendente() {
            dipendenteSelezionato.value = null;
        }

        /**
         * ! Geolocalizzazione foto
         */
        const geoOptions = {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 0,
        };

        const sendingCoordinates = ref(false);
        async function getPosition(options) {
            return new Promise((resolve, reject) =>
                navigator.geolocation.getCurrentPosition(
                    function(position) {
                        // L'utente ha fornito l'autorizzazione alla geolocalizzazione
                        //console.log(position);
                        const latitude = position.coords.latitude;
                        const longitude = position.coords.longitude;

                        return resolve(position);
                    },
                    function(error) {
                        //console.log(error);
                        if (error.code === error.PERMISSION_DENIED) {
                            sendingCoordinates.value = false;
                            openToast("Geolocalizzazione spenta o negata l'autorizzazione", "toast_danger");
                        } else {
                            sendingCoordinates.value = false;
                            openToast("Si è verificato un errore durante la geolocalizzazione", "toast_danger");
                        }
                        return reject(error);
                    }
                )
            );
        }

        /***************************************
         *
         * ! Foto da camera nativa
         *
         ****************************************/
        const loadingPhotos = ref(false);

        const fotoButton = ref(null);
        const inputButton = ref(null);
        const triggerCameraButton = () => {
            if (!categoriaSelezionata.value) {
                openToast("Prima di scegliere le foto devi selezionare una categoria", "toast_danger");
                return;
            }
            if (!dipendenteSelezionato.value) {
                openToast("Prima di scegliere le foto devi selezionare un operatore", "toast_danger");
                return;
            }

            if (fotoButton.value) {
                inputButton.value.click();
            }
        };

        const multipleFotoButton = ref(null);
        const multipleInputButton = ref(null);
        const triggerMultipleCameraButton = () => {
            if (!categoriaSelezionata.value) {
                openToast("Prima di scegliere le foto devi selezionare una categoria", "toast_danger");
                return;
            }
            if (!dipendenteSelezionato.value) {
                openToast("Prima di scegliere le foto devi selezionare un operatore", "toast_danger");
                return;
            }

            if (fotoButton.value) {
                multipleInputButton.value.click();
            }
        };

        const photos = ref([]);
        function getPhoto(event) {
            const files = event.target.files;
            Array.from(files).forEach((file) => {
                const reader = new FileReader();
                reader.onload = async (e) => {
                    //const currentBase64 = e.target.result;
                    const currentBase64 = e.target.result.split(",")[1];

                    // Categoria selezionata
                    const selectedCategory = categorieFoto.value.find((el) => el.rapportini_immagini_categoria_id === categoriaSelezionata.value);
                    let selectedCategoryLabel = "Generale";
                    if (selectedCategory) {
                        selectedCategoryLabel = selectedCategory.rapportini_immagini_categoria_value;
                    }
                    selectedPhotoCategory = categoriaSelezionata.value;

                    let pos = null;
                    if (richiediPosizione.value) {
                        // Ottengo coordinate
                        pos = await getPosition(geoOptions);
                        //console.log(pos);
                    }
                    // Ottengo coordinate
                    /* let pos = null;
                    pos = await getPosition(geoOptions); */
                    //console.log(pos);
                    // Gestisco file selezionato
                    const file = event.target.files[0];

                    // Estensione file
                    const fileExtension = file.name.split(".").pop();

                    const currentPhoto = {
                        name: file.filepath + "." + fileExtension,
                        file: file,
                        //filepath: file.lastModifised,
                        filepath: file.lastModified, //nome file generato in automatico
                        category: categoriaSelezionata.value, //categoria foto scelta
                        dipendente: dipendenteSelezionato.value, //operatore selezionato
                        latitude: pos ? pos.coords.latitude : null, //coordinate ottenute al momento
                        longitude: pos ? pos.coords.longitude : null, //coordinate ottenute al momento
                        tmp_src: URL.createObjectURL(file), // path temporaneo per visualizzare l'anteprima
                        exif: null, // EXIF estratti dalla fto
                        data: currentBase64, //base64 della foto
                        label: selectedCategoryLabel,
                    };
                    // Estraggo EXIF
                    let exifData = null;
                    exifData = await exifr.parse(URL.createObjectURL(file));
                    if (exifData) {
                        currentPhoto.exif = exifData;
                    }
                    console.log(exifData);
                    //Aggiunge all'array delle foto scattate
                    photos.value.push(currentPhoto);

                    //photos.value.push(e.target.result);
                };
                reader.readAsDataURL(file);
            });
        }

        async function processImage(file) {
            return new Promise((resolve, reject) => {
                new Compressor(file, {
                    quality: 0.75, // Imposta la qualità della compressione
                    retainExif: true, // Mantieni i dati EXIF
                    success(compressedFile) {
                        resolve(compressedFile);
                    },
                    error(err) {
                        reject(err);
                    },
                });
            });
        }

        /**
         *
         * ! CARICAMENTO MULTIPLO FOTO
         *
         */
        const loadingMultiplePhotos = ref(false);

        // Funzione per ottenere il base64 del file
        function getFileBase64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = () => resolve(reader.result.split(",")[1]);
                reader.onerror = (error) => reject(error);
                reader.readAsDataURL(file);
            });
        }

        async function getPhotosFromGallery(event) {
            loadingMultiplePhotos.value = true;

            // Ottieni coordinate una sola volta per tutte le foto
            let pos = null;
            if (richiediPosizione.value) {
                try {
                    pos = await getPosition(geoOptions);
                } catch (error) {
                    console.error("Error getting position", error);
                }
            }

            // Categoria selezionata
            const selectedCategory = categorieFoto.value.find((el) => el.rapportini_immagini_categoria_id === categoriaSelezionata.value);
            const selectedCategoryLabel = selectedCategory ? selectedCategory.rapportini_immagini_categoria_value : "Generale";

            const files = event.target.files;
            for (const file of files) {
                try {
                    // Estrai EXIF prima della compressione, comprime immagine ed ottiene base64
                    const exifData = await exifr.parse(file);
                    const compressedImage = await processImage(file, exifData);
                    const currentBase64 = await getFileBase64(compressedImage);

                    const fileExtension = file.name.split(".").pop();

                    // Imposta latitudine e longitudine
                    const currentLatitude = pos ? pos.coords.latitude : exifData && !isNaN(exifData.latitude) ? exifData.latitude : null;
                    const currentLongitude = pos ? pos.coords.longitude : exifData && !isNaN(exifData.longitude) ? exifData.longitude : null;

                    const currentPhoto = {
                        name: file.lastModified + "." + fileExtension,
                        file: compressedImage,
                        filepath: compressedImage.lastModified,
                        category: categoriaSelezionata.value,
                        dipendente: dipendenteSelezionato.value,
                        latitude: currentLatitude,
                        longitude: currentLongitude,
                        tmp_src: URL.createObjectURL(compressedImage),
                        exif: exifData,
                        data: currentBase64,
                        label: selectedCategoryLabel,
                    };

                    //console.log(exifData);

                    // Aggiungi all'array delle foto scattate
                    photos.value.push(currentPhoto);
                } catch (error) {
                    console.error("Error processing file", error);
                }
            }

            loadingMultiplePhotos.value = false;
        }

        /**
         *
         * ! Rimuove singla foto dall'array
         *
         */
        const cancellaFoto = async (photo) => {
            photos.value = photos.value.filter((p) => p.tmp_src !== photo.tmp_src);
        };
        const removePhoto = async (photo) => {
            const actionSheet = await actionSheetController.create({
                header: "Foto",
                buttons: [
                    {
                        text: "Elimina",
                        role: "destructive",
                        icon: trash,
                        handler: () => {
                            cancellaFoto(photo);
                        },
                    },
                    {
                        text: "Annulla",
                        icon: close,
                        role: "cancel",
                        handler: () => {
                            // Nothing to do, action sheet is automatically closed
                        },
                    },
                ],
            });
            await actionSheet.present();
        };

        /*****************************************************************
         *
         * ! Selezione foto tramite fotocamera o galleria, imposta categoria foto
         *
         *******************************************************************/
        const showActionSheetPhotos = async () => {
            if (categoriaSelezionata.value) {
                let pos = null;
                pos = await getPosition(geoOptions);
                //console.log(pos);

                const selectedCategory = categorieFoto.value.find((el) => el.rapportini_immagini_categoria_id === categoriaSelezionata.value);
                let selectedCategoryLabel = "Generale";
                if (selectedCategory) {
                    selectedCategoryLabel = selectedCategory.rapportini_immagini_categoria_value;
                }

                selectedPhotoCategory = categoriaSelezionata.value;

                const actionSheet = await actionSheetController.create({
                    header: "Scegli modalità di caricamento",
                    buttons: [
                        {
                            text: "Fotocamera",
                            icon: camera,
                            handler: () => {
                                takePhoto(selectedPhotoCategory, pos.coords.latitude, pos.coords.longitude, selectedCategoryLabel);
                            },
                        },
                        {
                            text: "Galleria",
                            icon: images,
                            handler: () => {
                                pickPhotos(selectedPhotoCategory, pos.coords.latitude, pos.coords.longitude, selectedCategoryLabel);
                            },
                        },
                        {
                            text: "Annulla",
                            icon: close,
                            role: "cancel",
                            handler: () => {
                                // Nothing to do, action sheet is automatically closed
                            },
                        },
                    ],
                });
                await actionSheet.present();
            } else {
                openToast("Devi selezionare una catagoria prima di scegliere le foto", "toast_danger");
            }
        };

        /**
         * ! Handle delete single photo
         */
        const showActionSheet = async (photo) => {
            const actionSheet = await actionSheetController.create({
                header: "Foto",
                buttons: [
                    {
                        text: "Elimina",
                        role: "destructive",
                        icon: trash,
                        handler: () => {
                            deletePhoto(photo);
                        },
                    },
                    {
                        text: "Annulla",
                        icon: close,
                        role: "cancel",
                        handler: () => {
                            // Nothing to do, action sheet is automatically closed
                        },
                    },
                ],
            });
            await actionSheet.present();
        };

        // Iterates all photos, convert to base64 and return.
        const conversioneFoto = async (userPhoto) => {
            const requests = userPhoto.map((photo) => {
                return imageToBase64(photo.webviewPath) // Path to the image
                    .then((response) => {
                        //console.log(response);
                        const fileName = photo.filepath;
                        const foto = {
                            name: fileName,
                            data: response,
                            category: photo.category,
                            latitude: photo.latitude,
                            longitude: photo.longitude,
                            label: photo.label,
                            dipendente: dipendenteSelezionato.value,
                        };
                        return foto;
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            });
            return Promise.all(requests); // Waiting for all the requests to get resolved.
        };

        const imgs_salvate = ref(null);
        //const photosToSave = ref(null);
        const photosToSave = ref([]);
        const submitted = ref(0); //contatore foto salvate
        const allPhotosCounter = ref(0);

        async function savePhoto() {
            console.log(photos.value);

            if (!categoriaSelezionata.value) {
                openToast("Devi selezionare una categoria prima di salvare le foto", "toast_danger");
                return;
            }
            if (!dipendenteSelezionato.value) {
                openToast("Devi selezionare un operatore prima di salvare le foto", "toast_danger");
                return;
            }

            if (photos.value.length === 0) {
                openToast("Non puoi salvare senza aver aggiunto almeno una foto", "toast_danger");
                return;
            } else {
                processingPhotos.value = true; //loading invio foto
                //const intervento = props.intervento_id;
                //console.log(userPhoto.value);

                /* const photosFromCamera = [];
                const toBeProcessedPhotos = [];
                let processedPhotos = [];

                userPhoto.value.forEach((el) => {
                    //console.log(el);
                    //Foto da galleria, prima le converto in b64
                    if (el.photoFromGallery) {
                        toBeProcessedPhotos.push(el);
                    } else {
                        //foto da camera, la formatto correttamente
                        photosFromCamera.push({
                            name: el.filepath,
                            data: el.webviewPath,
                            category: el.category,
                            latitude: el.latitude,
                            longitude: el.longitude,
                            dipendente: dipendenteSelezionato.value,
                        });
                    }
                });

                if (toBeProcessedPhotos.lenght != 0) {
                    processedPhotos = await conversioneFoto(toBeProcessedPhotos);
                }

                photosToSave.value = [...photosFromCamera, ...processedPhotos]; */

                //Modifico il rapportino inserendo le foto a posteriori dopo averlo effettivamente creato
                if (photos.value.length > 0) {
                    allPhotosCounter.value = photos.value.length;

                    //Salvo ogni singola foto con una chiamata diverse e se con successo aumento counter
                    const apiPromises = photos.value.map(async (photo) => {
                        try {
                            const response = await apiRapportini.salvaFotoRapportino(photo, props.rapportino_id);
                            //Aggiorno counter foro salvate
                            submitted.value++;
                            return response;
                        } catch (error) {
                            return { error: true, message: "Si è verificato un errore durante il salvataggio delle foto" };
                        }
                    });

                    try {
                        const responses = await Promise.all(apiPromises);
                        // Ora hai un array di risposte, puoi verificarle tutte alla fine
                        const allSuccessful = responses.every((response) => response.status === 200 && response.data.status === 1);

                        if (allSuccessful) {
                            openToast("Immagini caricate con successo", "toast_success");
                            //Svuoto foto selezionate
                            photos.value = [];
                            //deleteAllPhotos();
                            photosToSave.value = [];
                            //Svuoto categoria selezionata
                            removeSelectedCategory();
                            categoriaSelezionata.value = null;
                            submitted.value = 0;
                            //Chiudo modale
                            closeModal();
                        } else {
                            openToast("Non è stato possibile salvare le foto selezionate", "toast_danger");

                            // Almeno una chiamata API ha avuto un errore, mostra un alert "errore"
                            // Puoi anche visualizzare un messaggio specifico per ciascuna chiamata che ha avuto un errore
                            /* responses.forEach((response, index) => {
                                console.log(response);
                                if (response) {
                                    openToast(response.txt, "toast_danger");
                                    console.error(`Errore nella chiamata API per la foto ${index}`);
                                    console.error(response);
                                    console.error(JSON.parse(response));
                                }
                            }); */
                        }
                    } catch (error) {
                        console.error(error);
                        openToast("Non è stato possibile salvare le foto selezionate", "toast_danger");
                    } finally {
                        submitted.value = 0;
                        processingPhotos.value = false;
                    }

                    //const res = await salvaFoto(photosToSave);
                    //closeModalOnSubmit(photosToSave);
                }
            }
        }

        /* async function savePhoto() {
            console.log(photos.value);

            if (userPhoto.value.length === 0) {
                openToast("Non puoi salvare senza aver aggiunto almeno una foto", "toast_danger");
            } else {
                processingPhotos.value = true; //loading invio foto
                const intervento = props.intervento_id;
                //console.log(userPhoto.value);

                const photosFromCamera = [];
                const toBeProcessedPhotos = [];
                let processedPhotos = [];

                userPhoto.value.forEach((el) => {
                    //console.log(el);
                    //Foto da galleria, prima le converto in b64
                    if (el.photoFromGallery) {
                        toBeProcessedPhotos.push(el);
                    } else {
                        //foto da camera, la formatto correttamente
                        photosFromCamera.push({
                            name: el.filepath,
                            data: el.webviewPath,
                            category: el.category,
                            latitude: el.latitude,
                            longitude: el.longitude,
                            dipendente: dipendenteSelezionato.value,
                        });
                    }
                });

                if (toBeProcessedPhotos.lenght != 0) {
                    processedPhotos = await conversioneFoto(toBeProcessedPhotos);
                }

                photosToSave.value = [...photosFromCamera, ...processedPhotos];

                //Modifico il rapportino inserendo le foto a posteriori dopo averlo effettivamente creato
                if (photosToSave.value.length > 0) {
                    allPhotosCounter.value = photosToSave.value.length;

                    //Salvo ogni singola foto con una chiamata diverse e se con successo aumento counter
                    const apiPromises = photosToSave.value.map(async (photo) => {
                        try {
                            const response = await apiRapportini.salvaFotoRapportino(photo, props.rapportino_id);
                            //Aggiorno counter foro salvate
                            submitted.value++;
                            return response;
                        } catch (error) {
                            return { error: true, message: "Errore durante il salvataggio delle foto" };
                        }
                    });

                    try {
                        const responses = await Promise.all(apiPromises);
                        // Ora hai un array di risposte, puoi verificarle tutte alla fine
                        const allSuccessful = responses.every((response) => response.status === 200 && response.data.status === 1);

                        if (allSuccessful) {
                            openToast("Immagini caricate con successo", "toast_success");
                            //Svuoto foto caricate dal composable e foto appena visualizzate
                            deleteAllPhotos();
                            photosToSave.value = [];
                            //Svuoto categoria selezionata
                            removeSelectedCategory();
                            categoriaSelezionata.value = null;
                            submitted.value = 0;
                            //Chiudo modale
                            closeModal();
                        } else {
                            openToast("Non è stato possibile salvare le foto selezionate", "toast_danger");

                            // Almeno una chiamata API ha avuto un errore, mostra un alert "errore"
                            // Puoi anche visualizzare un messaggio specifico per ciascuna chiamata che ha avuto un errore
                            // responses.forEach((response, index) => {
                            //     console.log(response);
                            //     if (response) {
                            //         openToast(response.txt, "toast_danger");
                                    console.error(`Errore nella chiamata API per la foto ${index}: ${response.message}`);
                            //     }
                            // });
                        }
                    } catch (error) {
                        console.error(error);
                        openToast("Non è stato possibile salvare le foto selezionate", "toast_danger");
                    } finally {
                        submitted.value = 0;
                        processingPhotos.value = false;
                    }

                    //const res = await salvaFoto(photosToSave);
                    //closeModalOnSubmit(photosToSave);
                }
            }
        } */

        onMounted(() => {
            loadCategorie();
            loadDipendenti();
        });

        return {
            camera,
            trash,
            close,
            closeModal,
            closeModalOnSubmit,
            userPhoto,
            takePhoto,
            showActionSheet,
            savePhoto,
            processingPhotos,
            pickPhotos,
            showActionSheetPhotos,
            //Gestione categorie foto
            categorieFoto,
            categoriaSelezionata,
            setCategoria,
            removeSelectedCategory,
            //Counter foto salvate
            imgs_salvate,
            submitted,
            allPhotosCounter,
            //Gestione dipendente/operatore
            dipendenti,
            dipendenteSelezionato,
            setOperatore,
            removeSelectedDipendente,
            dipendenteID,
            // Caricamento foto da camera nativa
            fotoButton,
            triggerCameraButton,
            inputButton,
            getPhoto,
            photos,
            getPhotosFromGallery,
            removePhoto,
            multipleFotoButton,
            multipleInputButton,
            triggerMultipleCameraButton,
            loadingMultiplePhotos,
        };
    },
});
</script>

<style scoped>
ion-toolbar {
    --background: #086fa3;
    --color: #ffffff;
}
ion-button {
    --color: #ffffff;
}
.page_content {
    width: 100%;
    position: relative;
    min-height: 100%;
    background: #f8fafc;
    padding: 16px;
}

.loading_spinner {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.img_photo {
    position: relative;
}
.img_action {
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    justify-content: center;
    background-color: rgb(254 226 226);
    color: rgb(239 68 68);
    font-size: 14px;
    border-radius: 4px;
    padding: 4px;
}

.select100 {
    max-width: 100% !important;
}

.error_message {
    color: #eb445a;
}
.danger {
    color: #eb445a !important;
}

.alert-wrapper .ion-overlay-wrapper .sc-ion-alert-md {
    width: 100% !important;
}

.alert-wrapper {
    width: 90% !important;
}

/* New rules */
.container_actions_page {
    width: 100%;
    display: flex;
    justify-content: space-around;
}

.btn_close {
    --background: #dc2626;
    --background-activated: #b91c1c;
    --background-hover: #b91c1c;
    --background-focused: #b91c1c;
    --box-shadow: none;
    --border-radius: 4px;
    --padding-top: 10px;
    --padding-bottom: 10px;
    --padding-start: 10px;
    --padding-end: 10px;
    --ripple-color: none;
    --color: #ffffff;
    font-size: 14px;
    font-weight: 500;
    text-transform: capitalize;
    margin-bottom: 16px;
    width: 100%;
}
.btn_save {
    --background: #16a34a;
    --background-activated: #15803d;
    --background-hover: #15803d;
    --background-focused: #15803d;
    --box-shadow: none;
    --border-radius: 4px;
    --padding-top: 10px;
    --padding-bottom: 10px;
    --padding-start: 10px;
    --padding-end: 10px;
    --ripple-color: none;
    --color: #ffffff;
    font-size: 14px;
    font-weight: 500;
    text-transform: capitalize;
    width: 100%;
}

.container_help_text {
    margin-bottom: 16px;
    color: #64748b;
    font-size: 14px;
}

/**
  * New field
  */
.select_images_button {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    background-color: #086fa3;
    color: #ffffff;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
    transition: all 0.15s ease-in;
}

.container_actions {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 0 0 20px 0;
}
.btn_actions_images {
    width: 47%;
    display: flex;
    justify-content: center;
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 20px;
    background-color: rgb(226 232 240);
    color: rgb(148 163 184);
}

.category_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 24px;
}
.category_container ion-select {
    width: 100%;
}
.remove_category {
    width: 20px;
    height: 20px;
    color: #041219;
}

.foto_button_container {
    padding: 24px 0;
}
.foto_button {
    width: 100%;
    padding: 10px;
    font-size: 14px;
    font-weight: 600;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    background-color: #086fa3;
    color: #ffffff;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
    transition: all 0.15s ease-in;
}

.loading_photos {
    padding-bottom: 24px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 12px;
}
/**
* Anteprime foto
*/
.singola_foto {
    display: flex;
    justify-content: center;
    align-items: center;
}
ion-thumbnail {
    --size: 150px;
    --border-radius: 4px;
}
</style>
