import axios from "axios";

export default {
    async getImpostazioni() {
        const response = await axios.get("rest/v1/search/impostazioni_plan", {
            headers: {
                Authorization: `${process.env.VUE_APP_TOKEN}`,
            },
        });
        //console.log(response);
        return response;
    },
};
