
import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonSpinner, IonIcon, IonButton, modalController, IonButtons, menuController } from "@ionic/vue";
import { add, refresh, menu } from "ionicons/icons";

import { ref, onMounted } from "vue";
import { useRouter } from "vue-router";

import moment from "moment";

import { dateFormat } from "@/services/utils";
import { openToast } from "@/services/toast";

import apiRapportini from "@/services/rapportini";

import NuovoRapportino from "@/components/rapportini/NuovoRapportino.vue";
import ModificaRapportino from "@/components/rapportini/ModificaRapportino.vue";
import DettaglioRapportino from "@/components/rapportini/DettaglioRapportino.vue";

export default {
    name: "Rapportini",
    components: {
        IonHeader,
        IonToolbar,
        IonTitle,
        IonContent,
        IonPage,
        IonSpinner,
        IonIcon,
        IonButton,
        IonButtons,
    },
    setup() {
        const router = useRouter();
        const loading = ref(false);
        const userID = JSON.parse(localStorage.getItem("userPlanInfo")).dipendenti_user_id;
        const dipendenteID = JSON.parse(localStorage.getItem("userPlanInfo")).dipendenti_id;

        const openMenu = () => {
            menuController.open("app-menu");
        };

        const totalMesePrecedente = ref(0);
        const totalMeseCorrente = ref(0);

        function calculateWorkedHours(rapportini) {
            totalMesePrecedente.value = 0; // Resetta i valori a zero
            totalMeseCorrente.value = 0; // Resetta i valori a zero

            rapportini.forEach((rapportino) => {
                const start = moment(`${rapportino.rapportini_data} ${rapportino.rapportini_ora_inizio}`);
                const end = moment(`${rapportino.rapportini_data} ${rapportino.rapportini_ora_fine}`);
                const diff = moment.duration(end.diff(start)).asMinutes(); // Usa minuti per maggiore precisione

                const meseRapportino = Number(moment(rapportino.rapportini_data).format("M"));
                const annoRapportino = Number(moment(rapportino.rapportini_data).format("YYYY"));
                const meseCorrente = Number(moment().format("M"));
                const annoCorrente = Number(moment().format("YYYY"));

                if (annoRapportino === annoCorrente) {
                    if (meseRapportino === meseCorrente - 1) {
                        totalMesePrecedente.value += diff;
                    } else if (meseRapportino === meseCorrente) {
                        totalMeseCorrente.value += diff;
                    }
                }
            });

            totalMesePrecedente.value = totalMesePrecedente.value / 60; // Converti in ore con due decimali
            totalMeseCorrente.value = totalMeseCorrente.value / 60; // Converti in ore con due decimali
        }

        const rapportini = ref([]);
        async function loadRapportini() {
            loading.value = true;
            totalMesePrecedente.value = 0;
            totalMeseCorrente.value = 0;
            try {
                const response = await apiRapportini.getRapportini(userID);
                if (response.status === 0) {
                    rapportini.value = response.data;
                    calculateWorkedHours(response.data);
                } else {
                    openToast("Errore durante la richiesta dei rapportini", "toast_danger");
                }
            } catch (error) {
                console.error(error);
                openToast("Errore durante la richiesta dei rapportini", "toast_danger");
            }
            loading.value = false;
        }

        /**
         * ! Set correct customer data
         */
        const riferimentoCliente = (intervento) => {
            if (intervento.customers_company) {
                return intervento.customers_company;
            } else if (intervento.customers_name && !intervento.customers_last_name) {
                return `${intervento.customers_name}`;
            }
            return `${intervento.customers_name} ${intervento.customers_last_name}`;
        };

        /**
         * ! Open modal to create new intervento
         */
        async function openNuovoRapportino() {
            const modal = await modalController.create({
                component: NuovoRapportino,
            });
            modal.onDidDismiss().then((detail) => {
                if (detail.data != undefined) {
                    rapportini.value.unshift(detail.data);
                    calculateWorkedHours(rapportini.value);
                    openToast("Rapportino salvato con successo", "toast_success");
                }
            });
            return modal.present();
        }

        async function openDetail(rapportino) {
            const modal = await modalController.create({
                component: DettaglioRapportino,
                componentProps: {
                    data: rapportino,
                },
            });
            return modal.present();
        }

        async function openEditRapportino(rapportino) {
            //console.log(rapportino);
            const creazione_rapportino = moment(rapportino.rapportini_creation_date);
            const now = moment(new Date());
            const difference = moment.duration(now.diff(creazione_rapportino));

            //Permetto modifica entro le 24 ore dalla creazione
            if (difference.days() >= 1 || difference.hours() >= 24) {
                openToast("La modifica del rapportino è consentita entro 24 ore dalla sua creazione", "toast_danger");
            } else {
                const modal = await modalController.create({
                    component: ModificaRapportino,
                    componentProps: {
                        data: rapportino,
                    },
                });

                modal.onDidDismiss().then((detail) => {
                    //console.log(detail);
                    if (detail.data != undefined) {
                        const edited = rapportini.value.find((el) => el.rapportini_id === detail.data.rapportini_id);
                        //console.log(edited);
                        if (edited) {
                            edited.rapportini_ora_inizio = detail.data.rapportini_ora_inizio;
                            edited.rapportini_ora_fine = detail.data.rapportini_ora_fine;
                            edited.rapportini_note = detail.data.rapportini_note;

                            openToast("Rapportino modificato con successo", "toast_success");
                        }
                    }
                });

                return modal.present();
            }
        }

        onMounted(() => {
            loadRapportini();
        });

        return {
            loading,
            add,
            refresh,
            openNuovoRapportino,
            openMenu,
            menu,
            //Dati rapportini
            loadRapportini,
            rapportini,
            dateFormat,
            riferimentoCliente,
            totalMesePrecedente,
            totalMeseCorrente,
            //Modifica rapportino
            openEditRapportino,
            //Dettaglio rapportino
            openDetail,
        };
    },
};
