<template>
    <ion-page>
        <ion-header :translucent="true">
            <ion-toolbar>
                <ion-buttons slot="start">
                    <ion-back-button></ion-back-button>
                </ion-buttons>
                <ion-title color="white">Foto rapportino</ion-title>
            </ion-toolbar>
        </ion-header>

        <ion-content :fullscreen="true">
            <div class="page_content">
                <div v-if="processingPhotos" class="loading_spinner">
                    <ion-text color="dark">
                        <h5>Aggiunta delle foto in corso...</h5>
                    </ion-text>
                    <ion-spinner name="crescent" color="primary"></ion-spinner>
                </div>

                <div v-else>
                    <div class="container_actions">
                        <div @click="closeModal()" class="btn_actions_images">Chiudi</div>
                        <div @click="savePhoto()" class="btn_actions_images">Salva</div>
                    </div>

                    <div class="category_container">
                        <ion-select
                            name="tipologia"
                            interface="action-sheet"
                            cancel-text="Annulla"
                            ok-text="Conferma"
                            v-model="categoriaSelezionata"
                            value="1"
                            placeholder="Seleziona la categoria"
                            @ionChange="showActionSheetPhotos($event.target.value)"
                        >
                            <ion-select-option
                                v-for="categoria in categorieFoto"
                                :key="categoria.rapportini_immagini_categoria_id"
                                :value="categoria.rapportini_immagini_categoria_id"
                            >
                                {{ categoria.rapportini_immagini_categoria_value }}
                            </ion-select-option>
                        </ion-select>

                        <span @click="removeSelectedCategory()">
                            <ion-icon :icon="close" class="remove_category"></ion-icon>
                        </span>
                    </div>

                    <div class="photos_container">
                        <ion-grid>
                            <ion-row>
                                <ion-col size="6" :key="photo" v-for="photo in userPhoto">
                                    <!-- <ion-img :src="photo.webviewPath" class="img_photo"></ion-img> -->
                                    <ion-img v-if="photo.photoFromGallery" :src="photo.webviewPath" class="img_photo"></ion-img>
                                    <ion-img v-else :src="`data:image/png;base64,${photo.webviewPath}`" class="img_photo"></ion-img>
                                    <div @click="showActionSheet(photo)" class="img_action">
                                        <ion-icon :icon="trash"></ion-icon>
                                    </div>
                                </ion-col>
                            </ion-row>
                        </ion-grid>
                    </div>
                </div>
            </div>
        </ion-content>
    </ion-page>
</template>

<script>
import {
    IonPage,
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonBackButton,
    modalController,
    IonGrid,
    IonRow,
    IonCol,
    IonImg,
    IonIcon,
    actionSheetController,
    IonText,
    IonSelect,
    IonSelectOption,
    IonSpinner,
} from "@ionic/vue";
import { camera, trash, images, close } from "ionicons/icons";
import { defineComponent, ref, reactive, onMounted } from "vue";

import { openToast } from "@/services/toast";

import apiRapportini from "@/services/rapportini";

//import { usePhotoGallery, UserPhoto } from "@/services/usePhotoGalleryRapportino";
import { usePhotoGallery, UserPhoto } from "@/services/immagini_rapportino";
import imageToBase64 from "image-to-base64/browser";

export default defineComponent({
    name: "FotoRapportino",
    /*     props: {
        images: {
            type: Array,
            required: true,
        },
    }, */
    components: {
        IonPage,
        IonContent,
        IonHeader,
        IonTitle,
        IonToolbar,
        IonButtons,
        IonBackButton,
        IonGrid,
        IonRow,
        IonCol,
        IonImg,
        IonIcon,
        IonText,
        IonSelect,
        IonSelectOption,
        IonSpinner,
    },
    setup(props, context) {
        //console.log(props.images);

        //Camera functionality
        const { userPhoto, takePhoto, deletePhoto, pickPhotos } = usePhotoGallery();
        const processingPhotos = ref(false);

        /**
         * ! Handle close modal by clicking on Chiudi button or after upload foto
         * ! Send back data to visualize photos in Modal Pulizia form
         */
        const closeModal = () => {
            modalController.dismiss();
        };

        async function closeModalOnSubmit(res) {
            if (res) {
                await modalController.dismiss(res);
            } else {
                await modalController.dismiss();
            }
        }

        /**
         * ! Geolocalizzazione foto
         */
        const geoOptions = {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 0,
        };

        const sendingCoordinates = ref(false);
        async function getPosition(options) {
            return new Promise((resolve, reject) =>
                navigator.geolocation.getCurrentPosition(
                    function(position) {
                        // L'utente ha fornito l'autorizzazione alla geolocalizzazione
                        //console.log(position);
                        const latitude = position.coords.latitude;
                        const longitude = position.coords.longitude;

                        return resolve(position);
                    },
                    function(error) {
                        //console.log(error);
                        if (error.code === error.PERMISSION_DENIED) {
                            sendingCoordinates.value = false;
                            openToast("Geolocalizzazione spenta o negata l'autorizzazione", "toast_danger");
                        } else {
                            sendingCoordinates.value = false;
                            openToast("Si è verificato un errore durante la geolocalizzazione", "toast_danger");
                        }
                        return reject(error);
                    }
                )
            );
        }

        /**
         * ! Render button/select per scelta categoria foto
         */
        const categorieFoto = ref([]);
        let selectedPhotoCategory = null;
        const categoriaSelezionata = ref(null);

        async function getCategorie() {
            try {
                const res = await apiRapportini.getCategorieFoto();
                if (res.status === 200 && res.data.status === 0) {
                    categorieFoto.value = res.data.data;
                } else {
                    openToast("Errore durante la richiesta delle categorie", "toast_danger");
                }
            } catch (error) {
                console.error(error);
                openToast("Errore durante la richiesta delle categorie", "toast_danger");
            }
        }

        /**
         * Setta il reparto, obbligatorio prima di inserire la presenza
         * @param reparto_id string of selected reparto
         */
        function setCategoria(categoria_id) {
            categoriaSelezionata.value = categoria_id;
        }

        function removeSelectedCategory() {
            categoriaSelezionata.value = null;
        }

        /**
         * ! Selezione foto tramite fotocamera o galleria, imposta categoria foto
         */
        const showActionSheetPhotos = async (category_id) => {
            let pos = null;
            pos = await getPosition(geoOptions);
            //console.log(pos);

            const selectedCategory = categorieFoto.value.find((el) => el.rapportini_immagini_categoria_id === category_id);
            let selectedCategoryLabel = "Generale";
            if (selectedCategory) {
                selectedCategoryLabel = selectedCategory.rapportini_immagini_categoria_value;
            }

            if (category_id) {
                selectedPhotoCategory = category_id;

                const actionSheet = await actionSheetController.create({
                    header: "Scegli modalità di caricamento",
                    buttons: [
                        {
                            text: "Fotocamera",
                            icon: camera,
                            handler: () => {
                                takePhoto(selectedPhotoCategory, pos.coords.latitude, pos.coords.longitude, selectedCategoryLabel);
                            },
                        },
                        {
                            text: "Galleria",
                            icon: images,
                            handler: () => {
                                pickPhotos(selectedPhotoCategory, pos.coords.latitude, pos.coords.longitude, selectedCategoryLabel);
                            },
                        },
                        {
                            text: "Annulla",
                            icon: close,
                            role: "cancel",
                            handler: () => {
                                // Nothing to do, action sheet is automatically closed
                            },
                        },
                    ],
                });
                await actionSheet.present();
            }
        };

        /**
         * ! Handle delete single photo
         */
        const showActionSheet = async (photo) => {
            const actionSheet = await actionSheetController.create({
                header: "Foto",
                buttons: [
                    {
                        text: "Elimina",
                        role: "destructive",
                        icon: trash,
                        handler: () => {
                            deletePhoto(photo);
                        },
                    },
                    {
                        text: "Annulla",
                        icon: close,
                        role: "cancel",
                        handler: () => {
                            // Nothing to do, action sheet is automatically closed
                        },
                    },
                ],
            });
            await actionSheet.present();
        };

        // Iterates all photos, convert to base64 and return.
        const conversioneFoto = async (userPhoto) => {
            const requests = userPhoto.map((photo) => {
                return imageToBase64(photo.webviewPath) // Path to the image
                    .then((response) => {
                        //console.log(response);
                        const fileName = photo.filepath;
                        const foto = {
                            name: fileName,
                            data: response,
                            category: photo.category,
                            latitude: photo.latitude,
                            longitude: photo.longitude,
                            label: photo.label,
                        };
                        return foto;
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            });
            return Promise.all(requests); // Waiting for all the requests to get resolved.
        };

        const imgs_salvate = ref(null);
        async function savePhoto() {
            if (userPhoto.value.length === 0) {
                openToast("Non puoi salvare senza aver aggiunto almeno una foto", "toast_danger");
            } else {
                processingPhotos.value = true; //loading invio foto
                const intervento = props.intervento_id;
                //console.log(userPhoto.value);

                const photosFromCamera = [];
                const toBeProcessedPhotos = [];
                let processedPhotos = [];

                userPhoto.value.forEach((el) => {
                    //console.log(el);
                    //Foto da galleria, prima le converto in b64
                    if (el.photoFromGallery) {
                        toBeProcessedPhotos.push(el);
                    } else {
                        //foto da camera, la formatto correttamente
                        photosFromCamera.push({
                            name: el.filepath,
                            data: el.webviewPath,
                            category: el.category,
                            latitude: el.latitude,
                            longitude: el.longitude,
                        });
                    }
                });

                if (toBeProcessedPhotos.lenght != 0) {
                    processedPhotos = await conversioneFoto(toBeProcessedPhotos);
                }

                const photosToSave = [...photosFromCamera, ...processedPhotos];
                //console.log(photosToSave);
                processingPhotos.value = false;

                closeModalOnSubmit(photosToSave);
            }
        }

        onMounted(() => {
            getCategorie();
        });

        return {
            camera,
            trash,
            closeModal,
            closeModalOnSubmit,
            userPhoto,
            takePhoto,
            showActionSheet,
            savePhoto,
            processingPhotos,
            pickPhotos,
            showActionSheetPhotos,
            categorieFoto,
            categoriaSelezionata,
            setCategoria,
            removeSelectedCategory,
            close,
            imgs_salvate,
        };
    },
});
</script>

<style scoped>
ion-toolbar {
    --background: #086fa3;
    --color: #ffffff;
}
ion-button {
    --color: #ffffff;
}
.page_content {
    width: 100%;
    position: relative;
    min-height: 100%;
    background: #f8fafc;
    padding: 16px;
}

.loading_spinner {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.img_photo {
    position: relative;
}
.img_action {
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    justify-content: center;
    background-color: rgb(254 226 226);
    color: rgb(239 68 68);
    font-size: 14px;
    border-radius: 4px;
    padding: 4px;
}

.select100 {
    max-width: 100% !important;
}

.error_message {
    color: #eb445a;
}
.danger {
    color: #eb445a !important;
}

.alert-wrapper .ion-overlay-wrapper .sc-ion-alert-md {
    width: 100% !important;
}

.alert-wrapper {
    width: 90% !important;
}

/* New rules */
.container_actions_page {
    width: 100%;
    display: flex;
    justify-content: space-around;
}

.btn_close {
    --background: #dc2626;
    --background-activated: #b91c1c;
    --background-hover: #b91c1c;
    --background-focused: #b91c1c;
    --box-shadow: none;
    --border-radius: 4px;
    --padding-top: 10px;
    --padding-bottom: 10px;
    --padding-start: 10px;
    --padding-end: 10px;
    --ripple-color: none;
    --color: #ffffff;
    font-size: 14px;
    font-weight: 500;
    text-transform: capitalize;
    margin-bottom: 16px;
    width: 100%;
}
.btn_save {
    --background: #16a34a;
    --background-activated: #15803d;
    --background-hover: #15803d;
    --background-focused: #15803d;
    --box-shadow: none;
    --border-radius: 4px;
    --padding-top: 10px;
    --padding-bottom: 10px;
    --padding-start: 10px;
    --padding-end: 10px;
    --ripple-color: none;
    --color: #ffffff;
    font-size: 14px;
    font-weight: 500;
    text-transform: capitalize;
    width: 100%;
}

.container_help_text {
    margin-bottom: 16px;
    color: #64748b;
    font-size: 14px;
}

/**
  * New field
  */
.select_images_button {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    background-color: #086fa3;
    color: #ffffff;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
    transition: all 0.15s ease-in;
}

.container_actions {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 16px 0;
}
.btn_actions_images {
    width: 47%;
    display: flex;
    justify-content: center;
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 20px;
    background-color: rgb(226 232 240);
    color: rgb(148 163 184);
}

.category_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 24px;
}
.category_container ion-select {
    width: 100%;
}
.remove_category {
    width: 20px;
    height: 20px;
    color: #041219;
}
</style>
