<template>
    <ion-page>
        <ion-header :translucent="true">
            <ion-toolbar>
                <ion-title color="white">Dettaglio rapportino</ion-title>
                <ion-buttons slot="start">
                    <ion-button @click="closeModal()">
                        <ion-icon slot="start" :icon="arrowBackOutline" color="white"></ion-icon>
                    </ion-button>
                </ion-buttons>
                <!-- <ion-buttons v-if="!daRapportino" slot="start">
                    <ion-button @click="closeModal()">
                        <ion-icon slot="start" :icon="arrowBackOutline" color="white"></ion-icon>
                    </ion-button>
                </ion-buttons>
                <ion-buttons v-else slot="start">
                    <ion-button @click="goToProgramma()">
                        <ion-icon slot="start" :icon="arrowBackOutline" color="white"></ion-icon>
                    </ion-button>
                </ion-buttons> -->
            </ion-toolbar>
        </ion-header>

        <ion-content :fullscreen="true">
            <div class="page_content">
                <!-- Img modal, triggered with click on ticket attachment -->
                <Transition name="fade-transition">
                    <div v-show="showImgModal" class="attachment_modal">
                        <div class="customers_container">
                            <img :src="selectedImage" alt="Allegato ticket" />
                        </div>
                        <div @click="closeAttachmentModal()" class="close_modal">Chiudi</div>
                    </div>
                </Transition>

                <div class="rapportino">
                    <div v-if="rapportino.rapportini_codice" class="field">
                        <div class="title">Codice</div>
                        <div class="value">#{{ rapportino.rapportini_codice }}</div>
                    </div>
                    <div class="field">
                        <div class="title">Cliente</div>
                        <div class="value">
                            {{ setShortCustomer(rapportino.customers_company) }}
                        </div>
                    </div>
                    <div class="field">
                        <div class="title">Commessa</div>
                        <div class="value">
                            {{ rapportino.projects_name ? rapportino.projects_name : "-" }}
                        </div>
                    </div>
                    <div class="field">
                        <div class="title">Data</div>
                        <div class="value">
                            {{ dateFormat(rapportino.rapportini_data) }}
                        </div>
                    </div>
                    <div class="field">
                        <div class="title">Orario</div>
                        <div class="value">
                            {{ rapportino.rapportini_ora_inizio + " - " + rapportino.rapportini_ora_fine }}
                        </div>
                    </div>
                    <div class="field_note description">
                        <div class="title">Note</div>
                        <div class="value">
                            {{ rapportino.rapportini_note ? rapportino.rapportini_note : "-" }}
                        </div>
                    </div>
                    <div class="field_note description">
                        <div class="title">Operatori</div>
                        <div v-for="(operatore, index) in rapportino.rapportini_operatori" :key="index" class="value">
                            {{ operatore }}
                        </div>
                    </div>
                </div>

                <div class="actions_container">
                    <div class="foto_button" @click="openEditRapportino()">
                        Modifica rapportino
                    </div>
                    <div class="foto_button" @click="openImmaginiRapportino()">
                        Visualizza foto caricate
                    </div>
                    <div class="foto_button" @click="apriCaricaFoto()">
                        Carica foto
                    </div>
                    <!-- <div class="foto_button" @click="openSavePhotos()">
                        Aggiungi foto
                    </div> -->
                </div>
            </div>
        </ion-content>
    </ion-page>
</template>

<script>
import {
    IonPage,
    IonHeader,
    IonToolbar,
    IonButtons,
    //IonBackButton,
    IonTitle,
    IonContent,
    IonIcon,
    IonButton,
    modalController,
} from "@ionic/vue";

import { defineComponent, ref } from "vue";

import { arrowBackOutline } from "ionicons/icons";

import { dateFormat } from "@/services/utils";
import { openToast } from "@/services/toast";

//import interventiAPI from "@/services/interventi";

//import ModalFirmaIntervento from "@/components/ModalFirmaIntervento";
//import SalvaFotoRapportino from "@/components/rapportini/SalvaFotoRapportino";
import ModificaRapportino from "@/components/rapportini/ModificaRapportino";
import ImmaginiRapportino from "@/components/rapportini/ImmaginiRapportino";
import CaricaFoto from "@/components/rapportini/CaricaFoto";

export default defineComponent({
    name: "DettaglioRapportino",
    props: {
        data: {
            type: Object,
            required: true,
        },
        daRapportino: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        IonPage,
        IonHeader,
        IonToolbar,
        IonButtons,
        //IonBackButton,
        IonTitle,
        IonContent,
        IonIcon,
        IonButton,
    },
    setup(props, context) {
        const rapportino = ref([]);
        rapportino.value = { ...props.data };

        /**
         * ! Handle close modal by clicking on Chiudi button
         */
        const closeModal = () => {
            modalController.dismiss();
        };

        /**
         * ! Limit customers to 35 charactes
         */
        function setShortCustomer(customer) {
            if (!customer) {
                return "-";
            } else {
                const trimmedString = customer.length > 35 ? customer.substring(0, 32) + "..." : customer;
                return trimmedString;
            }
        }

        /**
         * ! Apertura pagina con immagini rapportino
         */
        async function openImmaginiRapportino() {
            const modal = await modalController.create({
                component: ImmaginiRapportino,
                componentProps: {
                    rapportino_id: rapportino.value.rapportini_id,
                },
            });
            return modal.present();
        }

        /**
         * ! Gestione foto rapportino
         */
        const immaginiRapportino = ref([]);
        async function apriCaricaFoto() {
            const modal = await modalController.create({
                component: CaricaFoto,
                componentProps: {
                    //images: immaginiRapportino.value,
                    rapportino_id: rapportino.value.rapportini_id,
                },
            });
            modal.onDidDismiss().then((detail) => {
                //console.log(detail);
                if (detail.data != undefined) {
                    const foto = detail.data;
                    if (foto) {
                        foto.forEach((singleFoto) => {
                            //console.log(singleFoto);
                            immaginiRapportino.value.push(singleFoto);
                        });
                    }
                }
            });
            return modal.present();
        }
        /*async function openSavePhotos() {
            const modal = await modalController.create({
                component: SalvaFotoRapportino,
                componentProps: {
                    images: immaginiRapportino.value,
                    rapportino_id: rapportino.value.rapportini_id,
                },
            });
            modal.onDidDismiss().then((detail) => {
                //console.log(detail);
                if (detail.data != undefined) {
                    const foto = detail.data;
                    if (foto) {
                        foto.forEach((singleFoto) => {
                            //console.log(singleFoto);
                            immaginiRapportino.value.push(singleFoto);
                        });
                    }
                }
            });
            return modal.present();
        } */

        /**
         *
         * ! Modifica dati base rapportino
         *
         */
        const successResponse = ref(false);
        async function openEditRapportino() {
            //console.log(rapportino.value);
            const modal = await modalController.create({
                component: ModificaRapportino,
                componentProps: {
                    data: rapportino.value,
                },
            });

            modal.onDidDismiss().then((detail) => {
                //console.log(detail);
                if (detail.data != undefined) {
                    successResponse.value = true;
                    rapportino.value.rapportini_ora_inizio = detail.data.rapportini_ora_inizio;
                    rapportino.value.rapportini_ora_fine = detail.data.rapportini_ora_fine;
                    rapportino.value.rapportini_note = detail.data.rapportini_note;
                    openToast("Rapportino modificato con successo", "toast_success");
                    /* console.log(successResponse.value);
                    console.log(detail.data); */
                } else {
                    successResponse.value = false;
                }
            });

            return modal.present();
        }

        /**
         *
         * ! Open fake modal to view selected ticket attachment
         *
         */
        const selectedImage = ref(null);
        const showImgModal = ref(false);
        function openImgDetail(image) {
            showImgModal.value = true;
            selectedImage.value = `${process.env.VUE_APP_BASE_URL}/uploads/${image.path_local}`;
        }

        function closeAttachmentModal() {
            showImgModal.value = false;
        }

        function setImageUrl(img) {
            if (img) {
                return `${process.env.VUE_APP_BASE_URL}/uploads/${img.path_local}`;
            }
        }

        return {
            dateFormat,
            rapportino,
            arrowBackOutline,
            closeModal,
            setShortCustomer,
            //Attachment modal
            showImgModal,
            selectedImage,
            openImgDetail,
            closeAttachmentModal,
            setImageUrl,
            //openSignature,
            //openSavePhotos,
            // Modifica rapportino
            openEditRapportino,
            // Modale con immagini rapportino
            openImmaginiRapportino,
            // Caricamento foto da camera nativa
            apriCaricaFoto,
        };
    },
});
</script>

<style scoped>
ion-toolbar {
    --background: #086fa3;
    --color: #ffffff;
}
.loading_spinner {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* New rules */
.page_content {
    width: 100%;
    position: relative; /* to be used for new intervento btn */
    padding: 16px;
    min-height: 100%;
    background: #f8fafc;
}

.rapportino .title_card {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    padding: 12px 6px;
    font-weight: 600;
    color: #475569;
}
.rapportino {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    padding: 10px;
    background: #ffffff;
    border-radius: 4px;
    margin-bottom: 10px;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
}
.rapportino .field {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 6px;
    margin-bottom: 10px;
    border-bottom: 1px solid #e2e8f0;
}
.rapportino .field_note {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    padding: 12px 6px;
}
.rapportino .field_note.description {
    border-bottom: 1px solid #e2e8f0;
}
.rapportino .field_note.description:last-of-type {
    border-bottom: none;
}
.field .title,
.field_note .title {
    font-size: 14px;
    font-weight: 500;
    color: #475569;
}
.field .ticket_title {
    text-transform: uppercase;
}
.field_note .title {
    margin-bottom: 8px;
}
.field .value,
.field_note .value {
    font-size: 14px;
}

.value .badge {
    font-size: 10px;
    font-weight: 600;
    color: #ffffff;
    padding: 4px 8px;
    border-radius: 4px;
}
.badge {
    font-size: 10px;
    color: #ffffff;
    padding: 4px 8px;
    border-radius: 4px;
}
.badge_close,
.badge_low {
    background-color: rgb(220 252 231);
    color: rgb(34 197 94);
    font-weight: bold;
}
.badge_standy,
.badge_high {
    background-color: rgb(254 226 226);
    color: rgb(239 68 68);
    font-weight: bold;
}
.badge_working,
.badge_medium {
    background-color: rgb(255 237 213);
    color: rgb(249 115 22);
    font-weight: bold;
}
.badge_waiting_answer {
    background-color: rgb(237 233 254);
    color: rgb(139 92 246);
    font-weight: bold;
}
.badge_open {
    background-color: rgb(207 250 254);
    color: rgb(6 182 212);
    font-weight: bold;
}
.badge_canceled {
    background-color: rgb(243 244 246);
    color: rgb(31 41 55);
    font-weight: bold;
}

.nuovo_intervento {
    width: 100%;
    margin-top: 16px;
    padding-bottom: 0;
    display: flex;
    justify-content: center;
}
.rapportino_button {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    background-color: #086fa3;
    color: #ffffff;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
    transition: all 0.15s ease-in;
}

/* Elenco interventi */
.interventi_container {
    margin-top: 16px;
}
.interventi_container .interventi_label {
    margin-bottom: 12px;
    font-size: 18px;
    font-weight: bold;
    color: #2a2a2a;
}
.rapportino {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 10px;
    background: #ffffff;
    border-radius: 4px;
    margin-bottom: 10px;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
}
.rapportino .date {
    font-size: 14px;
    font-weight: 500;
    color: #475569;
}
.rapportino .status {
    font-size: 14px;
}

ion-button {
    --color: #ffffff;
}
/** Allegati  */
.allegati_container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow-x: scroll;
}
.allegati_container img {
    width: 200px;
    margin-right: 16px;
}
.rapportino .field_allegati {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    padding: 12px;
    border-bottom: 1px solid #e2e8f0;
}
.field_allegati .title {
    font-size: 14px;
    font-weight: 500;
    color: #475569;
}
.field_allegati .title {
    margin-bottom: 8px;
}
.field_allegati .value {
    font-size: 14px;
}

/** Modal selected attachment */
.fade-transition-enter-active,
.fade-transition-leave-active {
    transition: opacity 0.25s ease;
}

.fade-transition-enter-from,
.fade-transition-leave-to {
    opacity: 0;
}
.attachment_modal {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.75);
    color: #ffffff;
    padding: 24px;
    align-items: center;
}
.close_modal {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    color: #ffffff;
    background-color: #086fa3;
    font-size: 16px;
    font-weight: 500;
    border-radius: 20px;
    margin-top: 16px;
}

.title.rapportino_container {
    display: flex;
    justify-content: center;
    margin-top: 8px;
    font-size: 16px;
}
a.link_rapportino {
    color: #086fa3;
    text-decoration: none;
}

/** Button foto */
.actions_container {
    padding: 12px 0;
    padding-top: 24px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.foto_button {
    width: 100%;
    padding: 10px;
    font-size: 14px;
    font-weight: 600;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    background-color: #086fa3;
    color: #ffffff;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
    transition: all 0.15s ease-in;
}

/* Button firme */
.btn_firme_container {
    display: flex;
    gap: 20px;
}
.btn_firme_container .btn_firma {
    width: 100%;
    margin-top: 16px;
    padding-bottom: 0;
    display: flex;
    justify-content: center;
}
.btn_firma .firma_button,
.email_button {
    width: 100%;
    padding: 10px;
    font-size: 14px;
    font-weight: 600;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    background-color: #086fa3;
    color: #ffffff;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
    transition: all 0.15s ease-in;
}

.btn_firma .firma_button:disabled,
.btn_firma .firma_button:disabled,
.email_container .email_button:disabled {
    opacity: 0.6;
}

/**
* Anteprime foto
*/
.singola_foto {
    display: flex;
    justify-content: center;
    align-items: center;
}
ion-thumbnail {
    --size: 150px;
    --border-radius: 4px;
}
</style>
