<template>
    <ion-page>
        <ion-header :translucent="true">
            <ion-toolbar>
                <ion-buttons slot="start">
                    <ion-button @click="closeModal()">
                        <ion-icon slot="start" :icon="arrowBackOutline" color="white"></ion-icon>
                    </ion-button>
                </ion-buttons>
                <ion-title color="white">Foto rapportino</ion-title>
            </ion-toolbar>
        </ion-header>

        <ion-content :fullscreen="true">
            <div class="page_content">
                <Transition name="fade-transition">
                    <div v-show="showModal" class="image_modal">
                        <img :src="setImageUrl(selectedPhoto)" alt="Foto rapportino" />
                        <div @click="clsoeImage()" class="close_modal">Chiudi</div>
                    </div>
                </Transition>

                <div v-if="loading" class="loading_spinner">
                    <ion-spinner name="crescent" color="dark"></ion-spinner>
                </div>

                <div v-else>
                    <div v-if="immagini.length == 0" class="no_immagini_container">
                        <div class="no_immagini">Nessuna foto da visualizzare</div>
                    </div>
                    <div v-else class="photos_container">
                        <ion-grid>
                            <ion-row>
                                <ion-col size="4" v-for="(photo, index) in immagini" :key="index">
                                    <ion-img :src="setImageUrl(photo)" @click="openImage(photo)" class="img_photo"></ion-img>
                                </ion-col>
                            </ion-row>
                        </ion-grid>
                    </div>
                </div>
            </div>
        </ion-content>
    </ion-page>
</template>

<script>
import {
    IonPage,
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonButton,
    IonGrid,
    IonRow,
    IonCol,
    IonImg,
    IonSpinner,
    modalController,
    IonIcon,
} from "@ionic/vue";
import { close, arrowBackOutline } from "ionicons/icons";
import { defineComponent, ref, reactive, onMounted } from "vue";

import { openToast } from "@/services/toast";

import apiRapportini from "@/services/rapportini";

export default defineComponent({
    name: "ImmaginiRapportino",
    props: {
        rapportino_id: {
            type: String,
            required: true,
        },
    },
    components: {
        IonPage,
        IonContent,
        IonHeader,
        IonTitle,
        IonToolbar,
        IonButtons,
        IonButton,
        IonGrid,
        IonRow,
        IonCol,
        IonImg,
        IonSpinner,
        IonIcon,
    },
    setup(props, context) {
        /**
         * ! Close current modal
         */
        const closeModal = () => {
            modalController.dismiss();
        };

        /**
         * ! Set image path
         * @param {string} image path
         */
        function setImageUrl(img) {
            if (img) {
                return `${process.env.VUE_APP_BASE_URL}/uploads/${img.rapportini_immagini_file}`;
            }
        }

        /**
         * ! Apre modale foto
         * @param {photo} img object
         */
        const showModal = ref(false);
        const selectedPhoto = ref(null);
        function openImage(photo) {
            selectedPhoto.value = photo;
            showModal.value = true;
        }
        function clsoeImage() {
            selectedPhoto.value = null;
            showModal.value = false;
        }

        /**
         * ! Get all rapportino images
         */
        const immagini = ref([]);
        const loading = ref(false);
        async function getImmaginiRapportino() {
            loading.value = true;
            try {
                const res = await apiRapportini.getImmagini(props.rapportino_id);
                if (res.status === 200 && res.data.status === 0) {
                    immagini.value = res.data.data;
                } else {
                    openToast("Si è verificato un errore durante la richiesta delle immagini", "toast_danger");
                }
            } catch (error) {
                console.error(error);
                openToast("Si è verificato un errore durante la richiesta delle immagini", "toast_danger");
            } finally {
                loading.value = false;
            }
        }

        onMounted(() => {
            getImmaginiRapportino();
        });

        return {
            arrowBackOutline,
            closeModal,
            immagini,
            loading,
            setImageUrl,
            openImage,
            clsoeImage,
            showModal,
            selectedPhoto,
        };
    },
});
</script>

<style scoped>
ion-toolbar {
    --background: #086fa3;
    --color: #ffffff;
}
ion-button {
    --color: #ffffff;
}
.page_content {
    width: 100%;
    position: relative;
    min-height: 100%;
    background: #f8fafc;
    padding: 16px;
}

.loading_spinner {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.img_photo {
    margin-bottom: 24px;
}
.img_action {
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    justify-content: center;
    background-color: rgb(254 226 226);
    color: rgb(239 68 68);
    font-size: 14px;
    border-radius: 4px;
    padding: 4px;
}

.select100 {
    max-width: 100% !important;
}

.error_message {
    color: #eb445a;
}
.danger {
    color: #eb445a !important;
}

.alert-wrapper .ion-overlay-wrapper .sc-ion-alert-md {
    width: 100% !important;
}

.alert-wrapper {
    width: 90% !important;
}

/* New rules */
.container_actions_page {
    width: 100%;
    display: flex;
    justify-content: space-around;
}

.btn_close {
    --background: #dc2626;
    --background-activated: #b91c1c;
    --background-hover: #b91c1c;
    --background-focused: #b91c1c;
    --box-shadow: none;
    --border-radius: 4px;
    --padding-top: 10px;
    --padding-bottom: 10px;
    --padding-start: 10px;
    --padding-end: 10px;
    --ripple-color: none;
    --color: #ffffff;
    font-size: 14px;
    font-weight: 500;
    text-transform: capitalize;
    margin-bottom: 16px;
    width: 100%;
}
.btn_save {
    --background: #16a34a;
    --background-activated: #15803d;
    --background-hover: #15803d;
    --background-focused: #15803d;
    --box-shadow: none;
    --border-radius: 4px;
    --padding-top: 10px;
    --padding-bottom: 10px;
    --padding-start: 10px;
    --padding-end: 10px;
    --ripple-color: none;
    --color: #ffffff;
    font-size: 14px;
    font-weight: 500;
    text-transform: capitalize;
    width: 100%;
}

.container_help_text {
    margin-bottom: 16px;
    color: #64748b;
    font-size: 14px;
}

/**
  * New field
  */
.select_images_button {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    background-color: #086fa3;
    color: #ffffff;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
    transition: all 0.15s ease-in;
}

.container_actions {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 16px 0;
}
.btn_actions_images {
    width: 47%;
    display: flex;
    justify-content: center;
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 20px;
    background-color: rgb(226 232 240);
    color: rgb(148 163 184);
}

.category_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 24px;
}
.category_container ion-select {
    width: 100%;
}
.remove_category {
    width: 20px;
    height: 20px;
    color: #041219;
}

.no_immagini_container {
    width: 100%;
    /*padding: 16px;*/
}

.no_immagini {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px;
    background-color: rgb(226 232 240);
    color: rgb(148 163 184);
    font-size: 16px;
    font-weight: 500;
    border-radius: 4px;
}

/** Modal selected attachment */
.fade-transition-enter-active,
.fade-transition-leave-active {
    transition: opacity 0.25s ease;
}

.fade-transition-enter-from,
.fade-transition-leave-to {
    opacity: 0;
}
.image_modal {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.75);
    color: #ffffff;
    padding: 24px;
    align-items: center;
}
.close_modal {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    color: #ffffff;
    background-color: #086fa3;
    font-size: 16px;
    font-weight: 500;
    border-radius: 20px;
    margin-top: 16px;
}
</style>
