import axios from "axios";

export default {
    async getDipendenti() {
        const response = await axios.get(`rest/v1/search/dipendenti`, {
            headers: {
                Authorization: process.env.VUE_APP_TOKEN,
            },
        });
        console.log(response);
        return response;
    },

    async setBarcode(dipendenteID: string, barcode: string) {
        const data = new FormData();
        data.append("dipendenti_badge_app", barcode);

        const response = await axios.post(`rest/v1/edit/dipendenti/${dipendenteID}`, data, {
            headers: {
                Authorization: process.env.VUE_APP_TOKEN,
            },
        });
        console.log(response);
        return response.data;
    },

    /**
     *
     * @param dipendenteID logged dipendente ID
     * @returns Dipendente badge in base64 to show personal QR
     */
    async getQrDipendente(dipendenteID: string) {
        //const response = await axios.get(`http://192.168.11.124/firegui_crm/modulo-hr/qrcode/generateImage/null/false/false/${dipendenteID}`, {
        const response = await axios.get(`${process.env.VUE_APP_BASE_URL}modulo-hr/qrcode/generateImage/null/false/false/${dipendenteID}`, {
            headers: {
                Authorization: process.env.VUE_APP_TOKEN,
            },
        });
        //console.log(response);
        return response;
    },
};
